"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Register = function Register() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var inputIndustryWrapper = document.querySelector('.fake-select');
    var inputIndustry = document.querySelector('.fake-select-input');
    var fakeOptionsWrapper = document.querySelector('.fake-options');
    var fakeOptions = document.querySelectorAll('.fake-option-value');
    var svg = document.querySelector('.fake-select-svg');
    var formLogin = document.querySelector('.login-form');
    var formReset = document.querySelector('.reset-form');
    var password = document.querySelector('[name="password"]');
    var newPassword = document.querySelector('[name="newPassword"]');
    var phone = document.querySelector('#phone');
    var extension = document.querySelector('#ext');
    var email = document.querySelector('[name="email"]');
    var confirmEmail = document.querySelector('#confirm_email');
    var confirmPass = document.querySelector('#confirm_pass');
    var confirmNewPassword = document.querySelector('#confirm_new_pass');
    var helperTextWrapper = document.querySelector('.helper-text');
    var cityHelperTextWrapper = document.querySelector(".city-helper-text");
    var togglePassword = document.querySelector('.toggle-password');
    var toggleNewPassword = document.querySelector('.toggle-new-password');
    var toggleConfirmPassword = document.querySelector('.toggle-confirm-password');
    var accountTypeToggle = document.querySelector('.toggle-wrapper');
    var accountTypeSelect = document.querySelector('#accountType');
    var businessOnlyInputs = document.querySelectorAll('[data-business-only]');
    var userSelfFirst = document.querySelector('[name="fields[employees][blocks][new1][fields][firstName]"]');
    var userSelfLast = document.querySelector('[name="fields[employees][blocks][new1][fields][lastName]"]');
    var userSelfJob = document.querySelector('[name="fields[employees][blocks][new1][fields][jobTitle]"]');
    var userSelfMail = document.querySelector('[name="fields[employees][blocks][new1][fields][mail]"]');
    var userSelfPhone = document.querySelector('[name="fields[employees][blocks][new1][fields][phone]"]');
    var urlCameFrom = document.querySelector('[name="fields[urlCameFrom]"]');
    var companyAddressCity = document.querySelector('[name="fields[compagny][blocks][new1][fields][companyAddressCity]"]');
    var accountOverlay = document.querySelector('.account-overlay');
    var helperText = {
      charLength: document.querySelector('.helper-text .length'),
      lowercase: document.querySelector('.helper-text .lowercase'),
      uppercase: document.querySelector('.helper-text .uppercase'),
      special: document.querySelector('.helper-text .special')
    };
    var cityHelperText = {
      alphabets: document.querySelector(".city-helper-text .alphabets")
    };
    var $formElements = [].slice.call(document.querySelectorAll('.ohnohoney-field'));
    var pattern = {
      charLength: function charLength(pass) {
        if (pass.value.length >= 8) {
          return true;
        }
      },
      lowercase: function lowercase(pass) {
        var regex = /^(?=.*[a-z]).+$/; // Lowercase character pattern

        if (regex.test(pass.value)) {
          return true;
        }
      },
      uppercase: function uppercase(pass) {
        var regex = /^(?=.*[A-Z]).+$/; // Uppercase character pattern

        if (regex.test(pass.value)) {
          return true;
        }
      },
      special: function special(pass) {
        var regex = /^(?=.*[_\W]).+$/; // Special character or number pattern

        if (regex.test(pass.value)) {
          return true;
        }
      },
      alphabets: function alphabets(pass) {
        var regex = /^[A-Za-z\s]+$/; // Alphabet characters only

        if (regex.test(pass.value)) {
          return true;
        }
      }
    };
    var count = 0;
    if (password && confirmPass || newPassword && confirmNewPassword) {
      var passwordFields = document.querySelectorAll('.password-field');
      passwordFields.forEach(function (passwordElement) {
        passwordElement.addEventListener('input', function (e) {
          if (password && confirmPass) {
            if (confirmPass.value && confirmPass.value !== password.value) {
              confirmPass.classList.add('is-not-same');
              document.querySelector('.not-same-message').classList.add('is-not-same');
            }
            if (confirmPass.value && confirmPass.value === password.value) {
              confirmPass.classList.remove('is-not-same');
              document.querySelector('.not-same-message').classList.remove('is-not-same');
            }
          }
          if (newPassword && confirmNewPassword) {
            if (confirmNewPassword.value && confirmNewPassword.value !== newPassword.value) {
              confirmNewPassword.classList.add('is-not-same');
              document.querySelector('.not-same-message').classList.add('is-not-same');
            }
            if (confirmNewPassword.value && confirmNewPassword.value === newPassword.value) {
              confirmNewPassword.classList.remove('is-not-same');
              document.querySelector('.not-same-message').classList.remove('is-not-same');
            }
          }
        });
      });
    }
    if (email && confirmEmail) {
      var emailFields = document.querySelectorAll('.email-field');
      emailFields.forEach(function (emailElement) {
        emailElement.addEventListener('input', function (e) {
          if (confirmEmail.value && confirmEmail.value != email.value) {
            confirmEmail.classList.add('is-not-same');
            document.querySelector('.not-same-email').classList.add('is-not-same');
          }
          if (confirmEmail.value && confirmEmail.value === email.value) {
            confirmEmail.classList.remove('is-not-same');
            document.querySelector('.not-same-email').classList.remove('is-not-same');
          }
        });
      });
    }
    if (userSelfFirst && window.location.pathname === '/register') {
      document.querySelector('[name="firstName"]').addEventListener('keyup', function (e) {
        userSelfFirst.value = e.target.value;
      });
      document.querySelector('[name="lastName"]').addEventListener('keyup', function (e) {
        userSelfLast.value = e.target.value;
      });
      document.querySelector('[name="fields[jobTitle]"]').addEventListener('keyup', function (e) {
        userSelfJob.value = e.target.value;
      });
      document.querySelector('[name="email"]').addEventListener('keyup', function (e) {
        userSelfMail.value = e.target.value;
        var validEmail = validateEmail(e.target.value);
        if (!validEmail) {
          document.querySelector('.email-format').classList.add('is-active');
          e.target.classList.add('is-not-same');
        } else {
          document.querySelector('.email-format').classList.remove('is-active', 'is-not-same');
          e.target.classList.remove('is-not-same');
        }
      });
      phone.addEventListener('keyup', function (e) {
        userSelfPhone.value = e.target.value;

        //14 length because of formatting
        if (e.target.value.length <= 13) {
          document.querySelector('.phone-format').classList.add('is-active', 'is-not-same');
          e.target.classList.add('is-not-same');
        } else {
          document.querySelector('.phone-format').classList.remove('is-active', 'is-not-same');
          e.target.classList.remove('is-not-same');
        }
      });
      if (window.localStorage.getItem('retroUrl')) {
        urlCameFrom.value = window.localStorage.getItem('retroUrl');
      }
      ;
    }
    if ($formElements) {
      $formElements.forEach(function (element) {
        element.autocomplete = 'new-password';
        element.autocorrect = 'off';
        element.autocapitalize = 'none';
        element.spellcheck = 'false';
      });
    }
    if (formLogin) {
      var honeyFields = formLogin.querySelectorAll('.ohnohoney-field');
      var submitBtn = formLogin.querySelector('button.button');
      var requiredFields = formLogin.querySelectorAll('[required]');
      var checkMail = formLogin.querySelector('[type="email"]');
      var test = 0;
      requiredFields.forEach(function (field) {
        field.addEventListener('blur', function () {
          var errorEl = field.parentElement.querySelector('.required-message');
          if (field.value === '') {
            field.classList.add('is-empty');
            errorEl.classList.add('is-required');
          } else {
            field.classList.remove('is-empty');
            errorEl.classList.remove('is-required');
          }
        });
      });
      var usernameEl = document.querySelector('[name="username"]');
      if (usernameEl) {
        usernameEl.addEventListener('blur', function () {
          axios({
            url: "actions/site-module/default/check-username?username=".concat(usernameEl.value),
            method: 'GET'
          }).then(function (res) {
            console.log('res :: ', {
              res: res
            });
            return res.data;
          }).then(function (data) {
            console.log('data :: ', data);
            var errorEl = usernameEl.parentElement.querySelector('.error-message');
            if (errorEl) {
              if (data.exists) {
                usernameEl.classList.add('is-empty');
                errorEl.classList.add('is-active');
              } else {
                if (usernameEl.value === '') {
                  usernameEl.classList.add('is-empty');
                } else {
                  usernameEl.classList.remove('is-empty');
                }
                errorEl.classList.remove('is-active');
              }
            }
          })["catch"](function (error) {
            console.log('error :: ', error);
          });
        });
      }
      if (submitBtn) {
        submitBtn.addEventListener('click', function (e) {
          e.preventDefault();

          // Run built-in HTML validation first
          if (formLogin.reportValidity() === false) {
            return;
          }
          if (window.location.pathname === '/register') {
            //Check if city field criteria is met
            if (!pattern.alphabets(companyAddressCity) && companyAddressCity.value) {
              document.querySelector(".city-criteria-not-met").classList.add("is-not-same");
            } else {
              document.querySelector(".city-criteria-not-met").classList.remove("is-not-same");
            }

            //Check if password criteria is met
            if (!(pattern.charLength(password) && pattern.lowercase(password) && pattern.uppercase(password) && pattern.special(password)) && password.value) {
              document.querySelector(".password-criteria-not-met").classList.add("is-not-same");
            } else {
              document.querySelector(".password-criteria-not-met").classList.remove("is-not-same");
            }
          }
          if (submitBtn.hasAttribute('data-old')) {
            var formData = new FormData();
            var checkedInputs = 0;
            for (var i = 0; i < formLogin.length; ++i) {
              if (formLogin[i].type === 'checkbox') {
                if (formLogin[i].checked) {
                  formData.append(formLogin[i].name, formLogin[i].value);
                  checkedInputs++;
                }
              } else {
                formData.append(formLogin[i].name, formLogin[i].value);
              }
            }
            axios({
              url: 'actions/site-module/default/check-old-users',
              method: 'POST',
              data: formData
            }).then(function (res) {
              console.log('res :: ', {
                res: res
              });
              // active overlay
              if (accountOverlay) {
                accountOverlay.classList.add('is-active');
              }
              formLogin.submit();
              return res.data;
            }).then(function (data) {
              console.log('data :: ', data);
              // active overlay
              if (accountOverlay) {
                accountOverlay.classList.add('is-active');
              }
              formLogin.submit();
            })["catch"](function (error) {
              console.log('error :: ', error);
            });
          } else {
            if (honeyFields) {
              honeyFields.forEach(function (item) {
                if (item.value != '') {
                  test += 1;
                }
              });
            }
            if (test >= 1) {
              window.alert('Too many connection attempts, please try again in a few minutes.');
            } else {
              if (requiredFields.length > 0) {
                var requiredFieldsNumbers = 0;
                var validationFields = 0;
                requiredFields.forEach(function (item, index) {
                  if (!item.classList.contains('input-business')) {
                    requiredFieldsNumbers += 1;
                    var errorEl = item.parentElement.querySelector('.required-message');
                    if (errorEl) {
                      if (item.value === '') {
                        item.classList.add('is-empty');
                        errorEl.classList.add('is-required');
                        validationFields -= 1;
                      } else {
                        item.classList.remove('is-empty');
                        errorEl.classList.remove('is-required');
                        validationFields += 1;
                      }
                    }
                  }
                });
                if (window.location.pathname === '/register' && !pattern.alphabets(companyAddressCity) && companyAddressCity.value) {
                  removeClass(companyAddressCity.parentElement, "valid");
                  cityHelperTextWrapper.classList.add("is-active");
                  validationFields -= 1;
                }
                if (requiredFieldsNumbers === validationFields) {
                  if (window.location.pathname === '/my-employee-list') {
                    formLogin.submit();
                  }
                  if (phone) {
                    if (phone.value.length !== 14) {
                      return;
                    }
                  }
                  if (password && confirmPass) {
                    if (password.value != '' && confirmPass.value != '' && password.value === confirmPass.value) {
                      if (pattern.lowercase(password) && pattern.charLength(password) && pattern.special(password) && pattern.uppercase(password)) {
                        if (password.value === confirmPass.value) {
                          // active overlay
                          if (accountOverlay) {
                            accountOverlay.classList.add('is-active');
                          }
                          if (checkMail) {
                            var verif = validateEmail(checkMail.value);
                            console.log('Verif 6 :: ' + verif);
                            if (confirmEmail && email) {
                              verif = email.value === confirmEmail.value;
                            }
                            if (verif) {
                              formLogin.submit();
                            } else {
                              // active overlay
                              if (accountOverlay) {
                                accountOverlay.classList.remove('is-active');
                              }
                              if (email.value == '') {
                                checkMail.nextElementSibling.classList.add('is-required');
                              }
                            }
                          } else {
                            formLogin.submit();
                          }
                        } else {
                          password.classList.add('red-flag');
                          confirmPass.classList.add('red-flag');
                          setTimeout(function () {
                            password.classList.remove('red-flag');
                            confirmPass.classList.remove('red-flag');
                          }, 1500);
                        }
                      } else {
                        if (pattern.lowercase() === undefined) {
                          addClass(helperText.lowercase, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.lowercase, 'no-valid');
                          }, 1500);
                        }
                        if (pattern.charLength() === undefined) {
                          addClass(helperText.charLength, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.charLength, 'no-valid');
                          }, 1500);
                        }
                        if (pattern.special() === undefined) {
                          addClass(helperText.special, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.special, 'no-valid');
                          }, 1500);
                        }
                        if (pattern.uppercase() === undefined) {
                          addClass(helperText.uppercase, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.uppercase, 'no-valid');
                          }, 1500);
                        }
                      }
                    }
                  } else if (newPassword && confirmNewPassword) {
                    if (newPassword.value != '' && confirmNewPassword.value != '') {
                      if (pattern.lowercase(newPassword) && pattern.charLength(newPassword) && pattern.special(newPassword) && pattern.uppercase(newPassword)) {
                        if (newPassword.value === confirmNewPassword.value) {
                          // active overlay
                          if (accountOverlay) {
                            accountOverlay.classList.add('is-active');
                          }
                          if (checkMail) {
                            var _verif = validateEmail(checkMail.value);
                            console.log('Verif 5 :: ' + _verif);
                            if (confirmEmail && email) {
                              _verif = email.value === confirmEmail.value;
                            }
                            if (_verif) {
                              formLogin.submit();
                            } else {
                              // active overlay
                              if (accountOverlay) {
                                accountOverlay.classList.remove('is-active');
                              }
                              if (email.value == '') {
                                checkMail.nextElementSibling.classList.add('is-required');
                              }
                            }
                          } else {
                            formLogin.submit();
                          }
                        } else {
                          newPassword.classList.add('red-flag');
                          confirmNewPassword.classList.add('red-flag');
                          setTimeout(function () {
                            newPassword.classList.remove('red-flag');
                            confirmNewPassword.classList.remove('red-flag');
                          }, 1500);
                        }
                      } else {
                        if (pattern.lowercase() === undefined) {
                          addClass(helperText.lowercase, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.lowercase, 'no-valid');
                          }, 1500);
                        }
                        if (pattern.charLength() === undefined) {
                          addClass(helperText.charLength, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.charLength, 'no-valid');
                          }, 1500);
                        }
                        if (pattern.special() === undefined) {
                          addClass(helperText.special, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.special, 'no-valid');
                          }, 1500);
                        }
                        if (pattern.uppercase() === undefined) {
                          addClass(helperText.uppercase, 'no-valid');
                          setTimeout(function () {
                            removeClass(helperText.uppercase, 'no-valid');
                          }, 1500);
                        }
                      }
                    }
                  } else {
                    // active overlay
                    if (accountOverlay) {
                      accountOverlay.classList.add('is-active');
                    }
                    if (checkMail) {
                      var _verif2 = validateEmail(checkMail.value);
                      console.log('Verif 4 :: ' + _verif2);
                      if (confirmEmail && email) {
                        _verif2 = email.value === confirmEmail.value;
                      }
                      if (_verif2) {
                        formLogin.submit();
                      } else {
                        // active overlay
                        if (accountOverlay) {
                          accountOverlay.classList.remove('is-active');
                        }
                        if (email.value == '') {
                          checkMail.nextElementSibling.classList.add('is-required');
                        }
                      }
                    } else {
                      formLogin.submit();
                    }
                  }
                }
              } else {
                if (password && confirmPass) {
                  if (password.value != '' && confirmPass.value != '') {
                    if (pattern.lowercase(password) && pattern.charLength(password) && pattern.special(password) && pattern.uppercase(password)) {
                      if (password.value === confirmPass.value) {
                        // active overlay
                        if (accountOverlay) {
                          accountOverlay.classList.add('is-active');
                        }
                        if (checkMail) {
                          var _verif3 = validateEmail(checkMail.value);
                          console.log('Verif 3 :: ' + _verif3);
                          if (confirmEmail && email) {
                            _verif3 = email.value === confirmEmail.value;
                          }
                          if (_verif3) {
                            formLogin.submit();
                          } else {
                            // active overlay
                            if (accountOverlay) {
                              accountOverlay.classList.remove('is-active');
                            }
                            if (email.value == '') {
                              checkMail.nextElementSibling.classList.add('is-required');
                            }
                          }
                        } else {
                          formLogin.submit();
                        }
                      } else {
                        password.classList.add('red-flag');
                        confirmPass.classList.add('red-flag');
                        setTimeout(function () {
                          password.classList.remove('red-flag');
                          confirmPass.classList.remove('red-flag');
                        }, 1500);
                      }
                    } else {
                      if (pattern.lowercase() === undefined) {
                        addClass(helperText.lowercase, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.lowercase, 'no-valid');
                        }, 1500);
                      }
                      if (pattern.charLength() === undefined) {
                        addClass(helperText.charLength, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.charLength, 'no-valid');
                        }, 1500);
                      }
                      if (pattern.special() === undefined) {
                        addClass(helperText.special, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.special, 'no-valid');
                        }, 1500);
                      }
                      if (pattern.uppercase() === undefined) {
                        addClass(helperText.uppercase, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.uppercase, 'no-valid');
                        }, 1500);
                      }
                    }
                  }
                } else if (newPassword && confirmNewPassword) {
                  if (newPassword.value != '' && confirmNewPassword.value != '') {
                    if (pattern.lowercase(newPassword) && pattern.charLength(newPassword) && pattern.special(newPassword) && pattern.uppercase(newPassword)) {
                      if (newPassword.value === confirmNewPassword.value) {
                        // active overlay
                        if (accountOverlay) {
                          accountOverlay.classList.add('is-active');
                        }
                        if (checkMail) {
                          var _verif4 = validateEmail(checkMail.value);
                          console.log('Verif 2 :: ' + _verif4);
                          if (confirmEmail && email) {
                            _verif4 = email.value === confirmEmail.value;
                          }
                          if (_verif4) {
                            formLogin.submit();
                          } else {
                            // active overlay
                            if (accountOverlay) {
                              accountOverlay.classList.remove('is-active');
                            }
                            if (email.value == '') {
                              checkMail.nextElementSibling.classList.add('is-required');
                            }
                          }
                        } else {
                          formLogin.submit();
                        }
                      } else {
                        newPassword.classList.add('red-flag');
                        confirmNewPassword.classList.add('red-flag');
                        setTimeout(function () {
                          newPassword.classList.remove('red-flag');
                          confirmNewPassword.classList.remove('red-flag');
                        }, 1500);
                      }
                    } else {
                      if (pattern.lowercase() === undefined) {
                        addClass(helperText.lowercase, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.lowercase, 'no-valid');
                        }, 1500);
                      }
                      if (pattern.charLength() === undefined) {
                        addClass(helperText.charLength, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.charLength, 'no-valid');
                        }, 1500);
                      }
                      if (pattern.special() === undefined) {
                        addClass(helperText.special, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.special, 'no-valid');
                        }, 1500);
                      }
                      if (pattern.uppercase() === undefined) {
                        addClass(helperText.uppercase, 'no-valid');
                        setTimeout(function () {
                          removeClass(helperText.uppercase, 'no-valid');
                        }, 1500);
                      }
                    }
                  }
                } else {
                  // active overlay
                  if (accountOverlay) {
                    accountOverlay.classList.add('is-active');
                  }
                  if (checkMail) {
                    var _verif5 = validateEmail(checkMail.value);
                    console.log('Verif 1 :: ' + _verif5);
                    if (confirmEmail && email) {
                      _verif5 = email.value === confirmEmail.value;
                    }
                    if (_verif5) {
                      formLogin.submit();
                    } else {
                      // active overlay
                      if (accountOverlay) {
                        accountOverlay.classList.remove('is-active');
                      }
                      if (email.value == '') {
                        checkMail.nextElementSibling.classList.add('is-required');
                      }
                    }
                  } else {
                    formLogin.submit();
                  }
                }
              }
            }
          }
        });
      }
    }
    if (formReset) {
      var _honeyFields = formReset.querySelectorAll('.ohnohoney-field');
      var _submitBtn = formReset.querySelector('.button');
      var _test = 0;
      _submitBtn.addEventListener('click', function (e) {
        e.preventDefault();
        var date = new Date();
        var time = localStorage.getItem('data_time');
        if (time === null) {
          localStorage.setItem('data_time', date);
        }
        if (time != date) {
          localStorage.removeItem('data_time');
        }
        if (_honeyFields) {
          _honeyFields.forEach(function (item) {
            if (item.value != '') {
              _test += 1;
            }
          });
        }
        if (_test >= 1) {
          window.alert('Too many connection attempts, please try again in a few minutes.');
        } else {
          if (time != date) {
            formReset.submit();
          } else {
            window.alert('wait a seconde !');
          }
        }
      });
    }
    if (inputIndustryWrapper && fakeOptions) {
      inputIndustryWrapper.addEventListener('click', function () {
        count += 1;
        fakeOptionsWrapper.classList.add('is-open');
        svg.classList.add('is-open');
        if (fakeOptionsWrapper.classList.contains('is-open') && count > 1) {
          fakeOptionsWrapper.classList.remove('is-open');
          svg.classList.remove('is-open');
          count = 0;
        } else {
          fakeOptionsWrapper.classList.add('is-open');
          svg.classList.add('is-open');
        }
      });
      fakeOptions.forEach(function (item) {
        item.addEventListener('click', function () {
          inputIndustry.value = item.textContent;
          fakeOptionsWrapper.classList.remove('is-open');
          svg.classList.remove('is-open');
          document.querySelector('.fake-input-error').classList.remove('is-required');
          inputIndustry.classList.remove('is-empty');
        });
      });
    }
    if (companyAddressCity) {
      companyAddressCity.addEventListener("keyup", function (e) {
        cityHelperTextWrapper.classList.add("is-active");
        // Check that city only contains alphabets
        patternTest(pattern.alphabets(companyAddressCity), cityHelperText.alphabets);
        // Check that all requirements are fulfilled
        if (hasClass(cityHelperText.alphabets, "valid")) {
          addClass(companyAddressCity.parentElement, "valid");
        } else {
          removeClass(companyAddressCity.parentElement, "valid");
        }
      });
    }
    if (password && confirmPass || newPassword && password && confirmNewPassword || newPassword) {
      if (password) {
        password.addEventListener('keyup', function () {
          helperTextWrapper.classList.add('is-active');

          // Check that password is a minimum of 8 characters
          patternTest(pattern.charLength(password), helperText.charLength);

          // Check that password contains a lowercase letter
          patternTest(pattern.lowercase(password), helperText.lowercase);

          // Check that password contains an uppercase letter
          patternTest(pattern.uppercase(password), helperText.uppercase);

          // Check that password contains a number or special character
          patternTest(pattern.special(password), helperText.special);

          // Check that all requirements are fulfilled
          if (hasClass(helperText.charLength, 'valid') && hasClass(helperText.lowercase, 'valid') && hasClass(helperText.uppercase, 'valid') && hasClass(helperText.special, 'valid')) {
            addClass(password.parentElement, 'valid');
          } else {
            removeClass(password.parentElement, 'valid');
          }
        });
      }
      if (newPassword) {
        newPassword.addEventListener('keyup', function () {
          helperTextWrapper.classList.add('is-active');

          // Check that password is a minimum of 8 characters
          patternTest(pattern.charLength(newPassword), helperText.charLength);

          // Check that password contains a lowercase letter
          patternTest(pattern.lowercase(newPassword), helperText.lowercase);

          // Check that password contains an uppercase letter
          patternTest(pattern.uppercase(newPassword), helperText.uppercase);

          // Check that password contains a number or special character
          patternTest(pattern.special(newPassword), helperText.special);

          // Check that all requirements are fulfilled
          if (hasClass(helperText.charLength, 'valid') && hasClass(helperText.lowercase, 'valid') && hasClass(helperText.uppercase, 'valid') && hasClass(helperText.special, 'valid')) {
            addClass(password.parentElement, 'valid');
          } else {
            removeClass(password.parentElement, 'valid');
          }
        });
      }
    }
    if (businessOnlyInputs) {
      businessOnlyInputs.forEach(function (item) {
        item.style.opacity = '1';
        item.style.height = 'auto';
        item.style.margin = '0 0 30px 0';
        var input = item.querySelector('input');
        var select = item.querySelector('select');
        if (input) {
          input.disabled = false;
        }
        if (select) {
          select.disabled = false;
        }
      });
    }
    if (accountTypeSelect) {
      accountTypeSelect.addEventListener('change', function (e) {
        var value = accountTypeSelect.value;
        if (value === 'business') {
          businessOnlyInputs.forEach(function (item) {
            item.style.opacity = '1';
            item.style.height = 'auto';
            item.style.margin = '0 0 30px 0';
            var input = item.querySelector('input');
            var select = item.querySelector('select');
            if (input) {
              input.classList.remove('input-business');
              input.disabled = false;
              input.required = true;
            }
            if (select) {
              select.classList.remove('input-business');
              select.disabled = false;
              select.required = true;
            }
          });
        } else {
          businessOnlyInputs.forEach(function (item) {
            item.style.opacity = '0';
            item.style.height = '0';
            item.style.margin = '0';
            var input = item.querySelector('input');
            var select = item.querySelector('select');
            if (input) {
              input.classList.add('input-business');
              input.disabled = true;
              input.required = false;
            }
            if (select) {
              select.classList.add('input-business');
              select.disabled = true;
              select.required = false;
            }
          });
        }
      });
    }
    if (togglePassword) {
      togglePassword.addEventListener('click', function (e) {
        var type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        var openEye = togglePassword.querySelector('.toggle-password-open');
        var closeEye = togglePassword.querySelector('.toggle-password-close');
        if (openEye.classList.contains('is-active')) {
          openEye.classList.remove('is-active');
          closeEye.classList.add('is-active');
        } else if (closeEye.classList.contains('is-active')) {
          openEye.classList.add('is-active');
          closeEye.classList.remove('is-active');
        }
      });
    }
    if (toggleConfirmPassword) {
      toggleConfirmPassword.addEventListener('click', function (e) {
        var type = confirmPass.getAttribute('type') === 'password' ? 'text' : 'password';
        confirmPass.setAttribute('type', type);
        var openEye = toggleConfirmPassword.querySelector('.toggle-password-open');
        var closeEye = toggleConfirmPassword.querySelector('.toggle-password-close');
        if (openEye.classList.contains('is-active')) {
          openEye.classList.remove('is-active');
          closeEye.classList.add('is-active');
        } else if (closeEye.classList.contains('is-active')) {
          openEye.classList.add('is-active');
          closeEye.classList.remove('is-active');
        }
      });
    }
    if (toggleNewPassword) {
      toggleNewPassword.addEventListener('click', function (e) {
        var type = newPassword.getAttribute('type') === 'password' ? 'text' : 'password';
        newPassword.setAttribute('type', type);
        var openEye = toggleNewPassword.querySelector('.toggle-password-open');
        var closeEye = toggleNewPassword.querySelector('.toggle-password-close');
        if (openEye.classList.contains('is-active')) {
          openEye.classList.remove('is-active');
          closeEye.classList.add('is-active');
        } else if (closeEye.classList.contains('is-active')) {
          openEye.classList.add('is-active');
          closeEye.classList.remove('is-active');
        }
      });
    }
    if (phone) {
      var isNumericInput = function isNumericInput(event) {
        var key = event.key;
        var numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        return numbers.includes(key); // Allow number line and Allow number pad
      };

      var isModifierKey = function isModifierKey(event) {
        var key = event.keyCode;
        return key === 35 || key === 36 ||
        // Allow Home, End
        key === 8 || key === 9 || key === 13 || key === 46 ||
        // Allow Backspace, Tab, Enter, Delete
        key > 36 && key < 41 ||
        // Allow left, up, right, down

        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90);
      };
      var enforceFormat = function enforceFormat(event) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if (!isNumericInput(event) && !isModifierKey(event)) {
          event.preventDefault();
        }
      };
      var formatToPhone = function formatToPhone(event) {
        if (isModifierKey(event)) {
          return;
        }
        var input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
        var areaCode = input.substring(0, 3);
        var middle = input.substring(3, 6);
        var last = input.substring(6, 10);
        if (input.length > 6) {
          event.target.value = "(".concat(areaCode, ") ").concat(middle, "-").concat(last);
        } else if (input.length > 3) {
          event.target.value = "(".concat(areaCode, ") ").concat(middle);
        } else if (input.length > 0) {
          event.target.value = "(".concat(areaCode);
        }
      };
      phone.addEventListener('keydown', enforceFormat);
      phone.addEventListener('keyup', formatToPhone);
      var formatHiddenPhone = function formatHiddenPhone(event) {
        var $field = document.querySelector('input[name="fields[phone]"]');
        if ($field) {
          var noHashExtension = extension.value.replace(/#/g, '');
          $field.value = phone.value + (noHashExtension ? '#' + noHashExtension : '');
          userSelfPhone.value = phone.value + (noHashExtension ? '#' + noHashExtension : '');
        }
      };
      phone.addEventListener('keyup', formatHiddenPhone);
      if (extension) {
        extension.addEventListener('keyup', formatHiddenPhone);
      }
    }
    function patternTest(pattern, response) {
      if (pattern) {
        addClass(response, 'valid');
      } else {
        removeClass(response, 'valid');
      }
    }
    function addClass(el, className) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += ' ' + className;
      }
    }
    function removeClass(el, className) {
      if (el.classList) el.classList.remove(className);else el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
    function hasClass(el, className) {
      if (el.classList) {
        return el.classList.contains(className);
      } else {
        new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
      }
    }
    function validateEmail(email) {
      return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }
    ;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();