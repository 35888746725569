"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Account = function Account() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var _this = this;
  var inited = false;
  var MAX_EMPLOYEES = 4;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Autocapitalize inputs
    autoCapitalize();
    fillHiddenPhone();
    var btnAddEmployee = document.querySelectorAll('.add-employees');
    var editEmployee = document.querySelectorAll('.edit-employee');
    var deleteUserEmployee = document.querySelectorAll('[data-delete-user-employee]');
    var employeeForm = document.querySelector('.employees-form');
    var employeeFormEdit = document.querySelector('.employees-form-edit');
    var overlayEmployeeForm = document.querySelector('.overlay-employees');
    var overlayEmployeeFormEdit = document.querySelector('.overlay-employees-edit');
    var closeEmployeeForm = document.querySelector('.close-form');
    var closeEmployeeFormEdit = document.querySelector('.close-form-edit');
    var urlParams = new URLSearchParams(window.location.search);
    var shopifyParam = urlParams.get('shopify');
    if (shopifyParam === '1') {
      var shopifyUrlParam = urlParams.get('url');
      if (!window.localStorage.getItem('retroUrl')) {
        window.localStorage.setItem('retroUrl', shopifyUrlParam);
      }
    }
    if (btnAddEmployee) {
      btnAddEmployee.forEach(function (btn) {
        btn.addEventListener('click', function (e) {
          e.preventDefault();
          employeeForm.classList.add('is-open');
        });
      });
    }
    if (overlayEmployeeForm) {
      overlayEmployeeForm.addEventListener('click', function (e) {
        e.preventDefault();
        employeeForm.classList.remove('is-open');
      });
    }
    if (closeEmployeeForm) {
      closeEmployeeForm.addEventListener('click', function (e) {
        e.preventDefault();
        employeeForm.classList.remove('is-open');
      });
    }
    if (deleteUserEmployee) {
      deleteUserEmployee.forEach(function (btn) {
        btn.addEventListener('click', function (e) {
          e.preventDefault();
          var formParentElement = btn.closest('form');
          var accountOverlay = document.querySelector('.account-overlay');
          if (accountOverlay && formParentElement) {
            accountOverlay.classList.add('is-active');
            setTimeout(function () {
              formParentElement.submit();
            }, 100);
          }
        });
      });
    }
    if (editEmployee) {
      editEmployee.forEach(function (edit) {
        edit.addEventListener('click', function (e) {
          e.preventDefault();
          employeeFormEdit.classList.add('is-open');
          var editFirstname = employeeFormEdit.querySelector('.edit-firstname');
          var editLastname = employeeFormEdit.querySelector('.edit-lastname');
          var editJobtitle = employeeFormEdit.querySelector('.edit-jobtitle');
          var editMail = employeeFormEdit.querySelector('.edit-mail');
          var editPhone = employeeFormEdit.querySelector('.edit-phone');
          var editExt = employeeFormEdit.querySelector('.edit-ext');
          var editHiddenPhone = employeeFormEdit.querySelector('.edit-hidden-phone');
          var currentPhone = '';
          var currentExt = '';
          if (editPhone) {
            editPhone.addEventListener('keydown', enforceFormat);
            editPhone.addEventListener('keyup', formatToPhone);
          }
          editFirstname.value = edit.dataset.employeeFirstname ? edit.dataset.employeeFirstname : '';
          editFirstname.name = 'fields[employees][entries][' + edit.dataset.editEmployee + '][fields][firstName]';
          editLastname.value = edit.dataset.employeeLastname ? edit.dataset.employeeLastname : '';
          editLastname.name = 'fields[employees][entries][' + edit.dataset.editEmployee + '][fields][lastName]';
          editJobtitle.value = edit.dataset.employeeJobtitle ? edit.dataset.employeeJobtitle : '';
          editJobtitle.name = 'fields[employees][entries][' + edit.dataset.editEmployee + '][fields][jobTitle]';
          editMail.value = edit.dataset.employeeMail ? edit.dataset.employeeMail : '';
          editMail.name = 'fields[employees][entries][' + edit.dataset.editEmployee + '][fields][mail]';
          editHiddenPhone.value = (edit.dataset.employeePhone ? edit.dataset.employeePhone : '') + (edit.dataset.employeeExt ? '#' + edit.dataset.employeeExt : '');
          editHiddenPhone.name = 'fields[employees][entries][' + edit.dataset.editEmployee + '][fields][phone]';
          editPhone.value = edit.dataset.employeePhone ? edit.dataset.employeePhone : '';
          editPhone.name = 'phone' + edit.dataset.editEmployee;
          editExt.value = edit.dataset.employeeExt ? edit.dataset.employeeExt : '';
          editExt.name = 'extension' + edit.dataset.editEmployee;
          var formatHiddenPhone = function formatHiddenPhone(event) {
            var phoneField = 'fields[employees][entries][' + edit.dataset.editEmployee + '][fields][phone]';
            var $field = employeeFormEdit.querySelector('input[name="' + phoneField + '"]');
            if ($field) {
              var hashRegExp = /#\S+/g;
              var noHashExtension = editExt.value.replace(hashRegExp, '');
              $field.value = editPhone.value + (noHashExtension ? '#' + noHashExtension : '');
            }
          };
          if (editPhone) {
            editPhone.addEventListener('keyup', formatHiddenPhone);
          }
          if (editExt) {
            editExt.addEventListener('keyup', formatHiddenPhone);
          }
        });
      });
      if (overlayEmployeeFormEdit) {
        overlayEmployeeFormEdit.addEventListener('click', function (e) {
          e.preventDefault();
          employeeFormEdit.classList.remove('is-open');
        });
      }
      if (closeEmployeeFormEdit) {
        closeEmployeeFormEdit.addEventListener('click', function (e) {
          e.preventDefault();
          employeeFormEdit.classList.remove('is-open');
        });
      }
    }
    var addMoreEmployee = document.querySelector('.employees-form .add-more-employee');
    if (addMoreEmployee) {
      addMoreEmployee.addEventListener('click', function (e) {
        e.preventDefault();
        addEmployee();
        autoCapitalize();
        fillHiddenPhone();
      });
    }
    var saveEmployeeSubmit = document.querySelector('.employees-form .save-employees');
    if (saveEmployeeSubmit) {
      saveEmployeeSubmit.addEventListener('click', function (e) {
        e.preventDefault();
        var isValid = validateForm();
        if (isValid) {
          var formParentElement = saveEmployeeSubmit.closest('.form');
          var accountOverlay = document.querySelector('.account-overlay');
          if (formParentElement && accountOverlay) {
            accountOverlay.classList.add('is-active');
            setTimeout(function () {
              formParentElement.submit();
            }, 100);
          }

          // setTimeout(() => {
          //     if (accountOverlay.classList.contains('is-active')) {
          //         accountOverlay.classList.remove('is-active');
          //     }
          // }, 10000);
        }
      });
    }

    return true;
  };
  var removeEmployee = function removeEmployee(event) {
    // Get the employee fields element that should be removed
    var employeeFields = event.target.closest('.employee-fields');

    // Remove the employee fields from the form
    employeeFields.parentElement.removeChild(employeeFields);

    // Get all the employees fields
    var employeesFields = document.querySelectorAll('.employee-fields');
    if (employeesFields.length < MAX_EMPLOYEES) {
      var addMoreEmployee = document.querySelector('.add-more-employee');
      addMoreEmployee.style.display = 'block';
    }

    // Update the sort order of the employees and the name fields
    employeesFields.forEach(function (employee, index) {
      // Update the title of header
      var title = employee.querySelector('.employee-fields-title');
      title.innerHTML = "Create New Employee ".concat(index + 1);
      var newId = "new".concat(index + 1);
      employee.querySelector('input[name="fields[employees][sortOrder][]"]').value = newId;
      employee.querySelector('input[name*="[type]"]').name = 'fields[employees][entries][' + newId + '][type]';
      employee.querySelector('input[name*="[fields][firstName]"]').name = 'fields[employees][entries][' + newId + '][fields][firstName]';
      employee.querySelector('input[name*="[fields][lastName]"]').name = 'fields[employees][entries][' + newId + '][fields][lastName]';
      employee.querySelector('input[name*="[fields][jobTitle]"]').name = 'fields[employees][entries][' + newId + '][fields][jobTitle]';
      employee.querySelector('input[name*="[fields][mail]"]').name = 'fields[employees][entries][' + newId + '][fields][mail]';
      employee.querySelector('input[name*="[fields][phone]"]').name = 'fields[employees][entries][' + newId + '][fields][phone]';
    });
  };
  var validateForm = function validateForm() {
    var employeeFields = document.querySelectorAll('.employee-fields');
    var isValid = true;
    employeeFields.forEach(function (employee, index) {
      var employeeId = "new".concat(index + 1);
      var employeeFirstnameField = employee.querySelector('[name="fields[employees][entries][' + employeeId + '][fields][firstName]"]');
      var employeeLastnameField = employee.querySelector('[name="fields[employees][entries][' + employeeId + '][fields][lastName]"]');
      var employeeJobtitleField = employee.querySelector('[name="fields[employees][entries][' + employeeId + '][fields][jobTitle]"]');
      var employeeMailField = employee.querySelector('[name="fields[employees][entries][' + employeeId + '][fields][mail]"]');
      var employeePhoneField = employee.querySelector('[name="fields[employees][entries][' + employeeId + '][fields][phone]"]');
      if (!employeeFirstnameField.value) {
        employeeFirstnameField.classList.add('is-empty');
        employeeFirstnameField.nextElementSibling.classList.add('is-required');
        isValid = false;
      } else {
        employeeFirstnameField.classList.remove('is-empty');
        employeeFirstnameField.nextElementSibling.classList.remove('is-required');
      }
      if (!employeeLastnameField.value) {
        employeeLastnameField.classList.add('is-empty');
        employeeLastnameField.nextElementSibling.classList.add('is-required');
        isValid = false;
      } else {
        employeeLastnameField.classList.remove('is-empty');
        employeeLastnameField.nextElementSibling.classList.remove('is-required');
      }
      if (!employeeJobtitleField.value) {
        employeeJobtitleField.classList.add('is-empty');
        employeeJobtitleField.nextElementSibling.classList.add('is-required');
        isValid = false;
      } else {
        employeeJobtitleField.classList.remove('is-empty');
        employeeJobtitleField.nextElementSibling.classList.remove('is-required');
      }
      if (!employeeMailField.value) {
        employeeMailField.classList.add('is-empty');
        employeeMailField.nextElementSibling.classList.add('is-required');
        isValid = false;
      } else {
        employeeMailField.classList.remove('is-empty');
        employeeMailField.nextElementSibling.classList.remove('is-required');
      }
      if (!employeePhoneField.value) {
        console.log(document.querySelector('input[name="base-phone-' + employeeId + '"]'));
        document.querySelector('input[name="base-phone-' + employeeId + '"]').classList.add('is-empty');
        document.querySelector('input[name="base-phone-' + employeeId + '"]').nextElementSibling.classList.add('is-required');
        isValid = false;
      } else {
        document.querySelector('input[name="base-phone-' + employeeId + '"]').classList.remove('is-empty');
        document.querySelector('input[name="base-phone-' + employeeId + '"]').nextElementSibling.classList.remove('is-required');
      }
    });
    return isValid;
  };
  var addEmployee = function addEmployee() {
    var employeeFields = document.querySelectorAll('.employee-fields');
    if (employeeFields.length < MAX_EMPLOYEES) {
      // Clone the existing employee fields
      var _employeeFields = document.querySelectorAll('.employee-fields')[0].cloneNode(true);

      // Generate a unique ID for the new employee fields
      var employeeId = generateUniqueId();

      // Update the names and values of the fields to reflect the unique ID
      updateFieldNames(_employeeFields, employeeId);
      updateEmployeeFieldsTitle(_employeeFields, employeeId);

      // Add the new employee fields to the form
      var employeesForm = document.querySelector('.employees-form');
      var employees = employeesForm.querySelector('.employees-form .form .employees');
      var removeButton = document.createElement('button');
      removeButton.innerHTML = "\n                <svg width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <path d=\"M11.7448 14L14 11.7448L9.13807 6.97071L14 2.19665L11.8033 0L7.02929 4.86192L2.25523 0L0 2.25523L4.86192 7.02929L0 11.8033L2.19665 14L6.97071 9.13807L11.7448 14Z\" fill=\"#5CB7E7\"/>\n                </svg>\n            ";
      removeButton.classList.add('remove-less-employee');
      removeButton.addEventListener('click', function (e) {
        e.preventDefault();
        removeEmployee(e);
      });
      removeButton.type = 'button';
      var employeeFieldsHeader = _employeeFields.querySelector('.employee-fields-header');
      employeeFieldsHeader.appendChild(removeButton);
      if (employees) {
        employees.appendChild(_employeeFields);
      }
      var requiredFields = employeesForm.querySelectorAll('[required]');
      requiredFields.forEach(function (field) {
        field.addEventListener('blur', function () {
          if (field.value === '') {
            field.classList.add('is-empty');
            field.nextElementSibling.classList.add('is-required');
          } else {
            field.classList.remove('is-empty');
            field.nextElementSibling.classList.remove('is-required');
          }
        });
      });
      var allEmployeeFields = document.querySelectorAll('.employee-fields');
      if (allEmployeeFields.length === MAX_EMPLOYEES) {
        var addMoreEmployee = document.querySelector('.add-more-employee');
        addMoreEmployee.style.display = 'none';
      }
    } else {
      var _addMoreEmployee = document.querySelector('.add-more-employee');
      _addMoreEmployee.style.display = 'none';
    }
  };
  var updateFieldNames = function updateFieldNames(employeeFields, employeeId) {
    var sortOrderField = employeeFields.querySelector('[name="fields[employees][sortOrder][]"]');
    sortOrderField.value = employeeId;
    var typeField = employeeFields.querySelector('[name="fields[employees][entries][new1][type]"]');
    typeField.name = 'fields[employees][entries][' + employeeId + '][type]';
    var firstNameField = employeeFields.querySelector('[name="fields[employees][entries][new1][fields][firstName]"]');
    firstNameField.name = 'fields[employees][entries][' + employeeId + '][fields][firstName]';
    firstNameField.value = '';
    var lastNameField = employeeFields.querySelector('[name="fields[employees][entries][new1][fields][lastName]"]');
    lastNameField.name = 'fields[employees][entries][' + employeeId + '][fields][lastName]';
    lastNameField.value = '';
    var jobTitleField = employeeFields.querySelector('[name="fields[employees][entries][new1][fields][jobTitle]"]');
    jobTitleField.name = 'fields[employees][entries][' + employeeId + '][fields][jobTitle]';
    jobTitleField.value = '';
    var mailField = employeeFields.querySelector('[name="fields[employees][entries][new1][fields][mail]"]');
    mailField.name = 'fields[employees][entries][' + employeeId + '][fields][mail]';
    mailField.value = '';
    var phoneField = employeeFields.querySelector('[name="fields[employees][entries][new1][fields][phone]"]');
    phoneField.name = 'fields[employees][entries][' + employeeId + '][fields][phone]';
    phoneField.value = '';
    var basePhoneField = employeeFields.querySelector('[name="base-phone-new1"]');
    basePhoneField.name = 'base-phone-' + employeeId;
    basePhoneField.value = '';
    basePhoneField.dataset.fieldId = employeeId;
    basePhoneField.addEventListener('keydown', enforceFormat);
    basePhoneField.addEventListener('keyup', formatToPhone);
    var extField = employeeFields.querySelector('[name="phone-ext-new1"]');
    extField.name = 'phone-ext-' + employeeId;
    extField.value = '';
    extField.dataset.fieldId = employeeId;
  };
  var generateUniqueId = function generateUniqueId() {
    // Generate a unique ID by incrementing the last number of "new"
    var employeeIds = document.querySelectorAll('.employee-fields input[name^="fields[employees][sortOrder]"]').map(function (input) {
      return input.value;
    });
    var lastEmployeeId = employeeIds.sort(function (a, b) {
      return b.substring(3) - a.substring(3);
    })[0];
    var lastEmployeeIdNumber = lastEmployeeId ? parseInt(lastEmployeeId.substring(3)) : 0;
    return 'new' + (lastEmployeeIdNumber + 1);
  };
  var updateEmployeeFieldsTitle = function updateEmployeeFieldsTitle(employeeFields, employeeId) {
    var employeeFieldsTitle = employeeFields.querySelector('.employee-fields-title');
    // remove 'new' in employeeId for the new title

    var formatedEmployeeId = employeeId.replace('new', '');
    employeeFieldsTitle.innerHTML = 'Create New Employee ' + formatedEmployeeId;
  };
  var autoCapitalize = function autoCapitalize() {
    var inputs = document.querySelectorAll('[data-capitalize]');
    if (inputs) {
      inputs.forEach(function (input) {
        input.addEventListener('change', debounce(function (e) {
          if (e.target.reportValidity()) {
            e.target.value = e.target.value.replace(/(^\w)|([-\s]\w)/g, function (match) {
              return match.toUpperCase();
            });
          }
        }));
      });
    }
  };
  var fillHiddenPhone = function fillHiddenPhone() {
    var inputs = document.querySelectorAll('[data-format-phone]');
    if (inputs) {
      inputs.forEach(function (input) {
        input.addEventListener('keyup', function (e) {
          var fieldId = input.dataset.fieldId;
          var phoneField = 'fields[employees][entries][' + fieldId + '][fields][phone]';
          var $field = document.querySelector('input[name="' + phoneField + '"]');
          var $extField = document.querySelector('input[name="phone-ext-' + fieldId + '"]');
          var $basePhoneField = document.querySelector('input[name="base-phone-' + fieldId + '"]');
          if ($field) {
            var noHashExtension = $extField.value.replace(/#/g, '');
            $field.value = $basePhoneField.value + (noHashExtension ? '#' + noHashExtension : '');
          }
        });
      });
    }
  };
  var isModifierKey = function isModifierKey(event) {
    var key = event.keyCode;
    return event.shiftKey === true || key === 35 || key === 36 ||
    // Allow Shift, Home, End
    key === 8 || key === 9 || key === 13 || key === 46 ||
    // Allow Backspace, Tab, Enter, Delete
    key > 36 && key < 41 ||
    // Allow left, up, right, down

    // Allow Ctrl/Command + A,C,V,X,Z
    (event.ctrlKey === true || event.metaKey === true) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90);
  };
  var isNumericInput = function isNumericInput(event) {
    var key = event.keyCode;
    return key >= 48 && key <= 57 ||
    // Allow number line
    key >= 96 && key <= 105 // Allow number pad
    ;
  };

  var enforceFormat = function enforceFormat(event) {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };
  var formatToPhone = function formatToPhone(event) {
    if (isModifierKey(event)) {
      return;
    }
    var input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    var areaCode = input.substring(0, 3);
    var middle = input.substring(3, 6);
    var last = input.substring(6, 10);
    if (input.length > 6) {
      event.target.value = "(".concat(areaCode, ") ").concat(middle, "-").concat(last);
    } else if (input.length > 3) {
      event.target.value = "(".concat(areaCode, ") ").concat(middle);
    } else if (input.length > 0) {
      event.target.value = "(".concat(areaCode);
    }
  };
  var debounce = function debounce(func, timeout) {
    var timer;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      clearTimeout(timer);
      timer = setTimeout(function () {
        func.apply(_this, args);
      }, timeout);
    };
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();